/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import CarouselBedrooms from "views/index-sections/CarouselBedrooms.js";
// import CarouselBedandbreakfast from "views/index-sections/CarouselBedandbreakfast.js";
import CarouselResidence from "views/index-sections/CarouselResidence.js";
import CarouselLocation from "views/index-sections/CarouselLocation.js";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionExamples() {
  return (
    <>
      <div id="gallery" className="section section-dark">
        <Container>
          <Row className="text-center upgrade-pro">
            <Col className="ml-auto mr-auto" lg="8">
              <h2 className="borgo-title">Galleria fotografica</h2>
            </Col>
          </Row>
          <Row className="example-page">
            <Col className="text-center" lg="4">
              <h3 className="title mx-auto">La Residenza</h3>
              <CarouselResidence/>
            </Col>
            <Col className="text-center" lg="4">
              <h3 className="title mx-auto">Le nostre camere</h3>
              <CarouselBedrooms />
            </Col>
            <Col className="text-center" lg="4">
              <h3 className="title mx-auto">Il territorio</h3>
              <CarouselLocation />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionExamples;
