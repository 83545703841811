/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";

// index sections
import SectionDescription from "views/index-sections/SectionDescription.js";
import SectionGallery from "views/index-sections/SectionGallery.js";
import SectionLocation from "views/index-sections/SectionLocation.js";
import SectionContact from "views/index-sections/SectionContact.js";

function Index() {
  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });

   
  return (
    <>
      <IndexNavbar />
      <IndexHeader />
      
      <div className="main">
        <SectionDescription />
        <SectionContact />
        <SectionLocation />
        <SectionGallery />

        <DemoFooter />
      </div>
    </>
  );
}

export default Index;
