import emailjs from "emailjs-com";
import React from "react";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

export default function ContactUs() {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "info_borgo_antico",
        "classic_template",
        e.target,
        "user_btbVWlqZ9eqrQwmvISiXA"
      )
      .then(
        (result) => {
          console.log("Email sent");
          console.log(JSON.stringify(result));
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }

  return (
    <>
      <div id="contacts" className="section section-pink">
        <Container>
          <Row className="text-center upgrade-pro">
            <Col className="ml-auto mr-auto">
              <h3 className="borgo-title">Contattateci! Assieme troveremo la soluzione ideale per il vostro soggiorno</h3>
            </Col>
          </Row>
          <div className="row">
            <div className="col-12">
              <form onSubmit={sendEmail}>
                <div className="row mx-auto">
                  <div className="col-lg-6 form-group mx-auto">
                    <div className="w-100">
                      <p className="w-100">
                        Nominativo
                        <input
                          type="text"
                          className="form-control w-100"
                          placeholder="Sig. Mario Rossi"
                          name="name"
                        />
                      </p>
                    </div>
                    <div className="w-100">
                      <p className="w-100">
                        L'indirizzo email a cui contattarti
                        <input
                          type="email"
                          className="form-control w-100"
                          placeholder="mario@rossi.it"
                          name="email"
                        />
                      </p>
                    </div>
                    <div className="w-100">
                      <p className="w-100">
                        Oggetto della mail
                        <input
                          type="text"
                          className="form-control w-100"
                          placeholder="Richiesta maggiori informazioni"
                          name="subject"
                        />
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 form-group mx-auto">
                    <p className="w-100">
                      Messaggio
                      <textarea
                        className="form-control"
                        id=""
                        cols="30"
                        rows="7"
                        placeholder="Buongiorno, scrivo questo messaggio per..."
                        name="message"
                      ></textarea>
                    </p>
                  </div>
                </div>
                <div className="row mx-auto">
                  <div className="col-12">
                    <input
                      type="submit"
                      className="btn btn-info float-right"
                      value="Invia messaggio"
                    ></input>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-12">
              <div className="col-12">
                <p>Telefono: 393 7313833</p>
                <p>Codice CIPAT: 022143-AT-011618</p>
                <p>Pinzolo (Tn) - 38086, Via Genova, 103 bis </p>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
