/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import Map from "views/index-sections/Map.js";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";

function SectionLocation() {
  const [activeTab, setActiveTab] = React.useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <>
      <div id="location" className="section section-buttons">
        <Container>
          <Row className="text-center upgrade-pro">
            <Col className="ml-auto mr-auto">
              <h2 className="borgo-title">La nostra realtà</h2>
            </Col>
          </Row>
          <Row>
            <Map />
          </Row>
          <Row className="mt-4">
            <Col>
              <div className="nav-tabs-navigation">
                <div className="nav-tabs-wrapper">
                  <Nav id="tabs" role="tablist" tabs>
                    <NavItem>
                      <NavLink
                        className={activeTab === "1" ? "active" : ""}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Noi
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab === "2" ? "active" : ""}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        Pinzolo
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab === "3" ? "active" : ""}
                        onClick={() => {
                          toggle("3");
                        }}
                      >
                        Val Rendena
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <p>
                    Dalla ristrutturazione avvenuta nel 2008 abbiamo ricavato una Residenza, 
                    districandoci tra spessi muri di sassi,
                    soffitti ad avvolto e pavimenti di vario livello. La vecchia
                    abitazione costruita a partire dal 1500 ha subito, nei vari
                    anni, interventi di tipo conservativo, tetto, intonaco, ma
                    la struttura è rimasta solida nel tempo, grossi massi uno
                    accanto all'altro hanno aspettato con pazienza l'arrivo del
                    totale cambiamento e della sua rinascita. Sempre appartenuta
                    alla nostra famiglia, ora accoglie con professionalità e
                    competenza coloro che vorranno da noi trascorrere una
                    vacanza di tranquillità e serenità.
                  </p>
                </TabPane>
                <TabPane tabId="2">
                  <p>
                    "Al Borgo Antico" si trova a 5 minuti da parco giochi attrezzato, spazi
                    verdi e pista ciclabile con destinazione Tione o Madonna di
                    Campiglio.
                    <br />
                    A 400m da noi si trovano gli impianti di risalita (Prarodont
                    - Doss del Sabion) e la chiesa S.Vigilio (Baschenis).
                    <br />
                    Tra gli altri servizi vicini a noi, ci sono la biblioteca
                    comunale, il palaghiaccio, il bocciodromo, il nuovissimo
                    biolago, la farmacia e il cinema teatro Paladolomiti.
                    <br />
                    Gli sport che si possono praticare sono svariati: si passa dalle escursioni in montagna, ai giri in bicicletta, voli in parapendio, sci, snowboard, ciaspole, arrampicata.
                    <br />
                    Maggiori informazioni si possono trovare sul sito dell'Apt a
                    questo
                    <a
                      href="https://www.campigliodolomiti.it/"
                    >
                      &nbsp;link
                    </a>.
                  </p>
                </TabPane>
                <TabPane tabId="3">
                  <p>
                    Muovendosi in valle, l'offerta è ancora più ampia.
                    <br />
                    A 3km da noi, a Caderzone Terme si trovano le terme Fonte S.Antonio.
                    <br />
                    Nel vicino paese di Bocenago (sempre a 3km), si trova il Golf
                    Club Rendena.
                    <br />
                    A Carisolo, ad appena 1km di distanza, il Palazzetto dello
                    sport offre una palestra di roccia, la palestra pesi, una
                    sauna, campi da tennis (indoor/outdoor) e, in inverno, pista
                    da fondo con noleggio sci.
                    <br />
                    La piscina pubblica più vicina si trova a Spiazzo, a 6km di distanza.
                    <br />
                    La Val Rendena si trova al centro geografico del Parco Naturale Adamello Brenta Geopark, famoso per la Val Genova e le sue Cascate Nardis.
                    <br />
                    Infine, non possiamo non citare la Perla delle Dolomiti: Madonna di Campiglio, la capitale indiscussa per gli sport e le vacanze invernali, si trova a 13km di distanza. Visitatela anche d'estate!
                  </p>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionLocation;
