/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";

function SectionDescription() {
 
  return (
    <>
      <div id="description" className="section section-buttons">
        <Container>
          <Row className="text-center upgrade-pro">
            <Col className="ml-auto mr-auto" md="8">
              <h2 className="borgo-title">"Al Borgo Antico" - Residenza</h2>
              <p className="description">
                Situato in zona tranquilla, dista circa 400 metri dagli impianti
                sciistici, ad un passo dal centro ed a pochi minuti dal parco
                Pineta (centro sportivo). 
              </p>
              <p className="description">
                Dispone di parcheggio privato, deposito sci e scarponi.
              </p>
              <p className="description">
                Due ampie camere dotate di bagno privato, TV ed asciugacapelli.
              </p>
              <p className="description">
                Zona giorno con angolo cottura, TV e bagno di servizio.
              </p>
              <p className="description">
                L'arredamento è in stile montano, curato in ogni dettaglio che rende caldo l'ambiente e piacevole il riposo.
              </p>
              <p className="description">
                Convenzioni con alcuni ristoranti della zona.
              </p>
            </Col>
            <Col className="ml-auto mr-auto" sm="5">
              <Button className="btn-round" color="pink" href="#gallery">
                <i aria-hidden={true} className="" /> Guarda le nostre gallerie
                di immagini!
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionDescription;


{/* <p className="description">
Situato in zona tranquilla, dista circa 400 metri dagli impianti
sciistici, ad un passo dal centro ed a pochi minuti dal parco
Pineta (centro sportivo). 
</p>
<p>
Dispone di parcheggio privato, deposito sci e scarponi.
</p>
<p className="description">
Due ampie camere dotate di bagno privato, TV ed asciugacapelli.
</p>
<p className="description">
Zona giorno con angolo cottura, TV e bagno di servizio.
</p>
<p className="description">
L'arredamento è in stile montano, curato in ogni dettaglio che rende caldo l'ambiente giorno con angolo cottura, TV e bagno di servizio.
</p> */}