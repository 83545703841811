/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import { isWhiteSpaceLike } from "typescript";

// core components

function IndexHeader() {
  return (
    <>
      <div
        id="home"
        className="page-header section-dark"
        style={{
          backgroundImage:
            "url(" + require("assets/img/home.jpg").default + ")",
        }}
      >
        {/* <div className="filter" /> */}
        <div className="content-center">
          <Container>
            <div className="title-brand dark-wrapper">
              <h1 className="presentation-title-line-one"  style={{whiteSpace: 'nowrap'}} >"Al Borgo Antico"</h1>
              {/*<h2 className="presentation-title-line-two"></h2>*/}

              {/* <div className="fog-low">
                <img
                  alt="..."
                  src={require("assets/img/fog-low.png").default}
                />
              </div>
              <div className="fog-low right">
                <img
                  alt="..."
                  src={require("assets/img/fog-low.png").default}
                />
              </div> */}
            </div>
            <h2 className="presentation-subtitle text-center dark-wrapper">
            Residenza Pinzolo 
              {/* -- Val Rendena - Madonna di Campiglio */}
            </h2>
          </Container>
        </div>
        {/* <div
          className="moving-clouds"
          style={{
            backgroundImage:
              "url(" + require("assets/img/clouds.png").default + ")",
          }}
        /> */}
      </div>
    </>
  );
}

export default IndexHeader;
