/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Row, Col, Container } from "reactstrap";

const logoMaffei =
{
  src: require("assets/img/logo-maffei.png").default,
  altText: "Logo Maffei",
  caption: "Logo Maffei",
};

const logoApt = {
  src: require("assets/img/logo-apt.png").default,
  altText: "Logo Apt",
  caption: "Il logo dell'Apt",
};

const logoPnab = {
  src: require("assets/img/logo-pnab.png").default,
  altText: "Logo Pnab",
  caption: "Il logo del pnab",
};

function DemoFooter() {
  return (
    <footer className="footer section-dark">
      <Container>
        <Row>
          <Col lg="6">
            <div className="text-center">
              {/* ## */}
              <table>
                <tr>
                  <td>
                    <a
                      href="https://www.pnab.it/"
                      className="reference-link text-secondary px-5 "
                      target="_blank">
                      <img
                        src={logoPnab.src}
                        alt={logoPnab.altText}
                        className="reference-logo"
                      />
                    </a>
                  </td>
                  <td style={{ textAlign: "left" }}>
                    <a
                      href="https://www.pnab.it/"
                      className="reference-link text-secondary"
                      target="_blank">
                      <span>&nbsp;Parco Naturale Adamello Brenta</span>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.campigliodolomiti.it/"
                      className="reference-link text-secondary px-5"
                      target="_blank"
                    >
                      <img
                        src={logoApt.src}
                        alt={logoApt.altText}
                        className="reference-logo"
                      />
                    </a>
                  </td>
                  <td style={{ textAlign: "left" }}>
                    <a
                      href="https://www.campigliodolomiti.it/"
                      className="reference-link text-secondary"
                      target="_blank"
                    >
                      <span>&nbsp;Apt Campiglio</span>
                    </a>
                  </td>
                </tr>
              </table>
            </div>
          </Col>
          <Col lg="6" className="text-center">
            <div className="col-12">
              <div className="col-12">
                <p>
                  <span className="mx-2">Tel.: 393 7313833</span>
                  <span>CIPAT: 022143-AT-011618</span>
                </p>
                <p>Pinzolo (Tn) - 38086, Via Genova, 103 bis </p>
              </div>
            </div>
            <div className="ml-auto text-center">
              <span className="copyright">
                © {new Date().getFullYear()},
                {/* Impianti idraulici montaggio e
                manutenzione caldaie e bruciatori  */}
                &nbsp; PI 01799650229
              </span>
            </div>
            {/* <img src={logoMaffei.src} alt={logoMaffei.altText} /> */}
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;



// used to be ##
// <div>
// <a
//   href="https://www.pnab.it/"
//   className="reference-link text-secondary"
//   target="_blank"
// >
//   <img
//     src={logoPnab.src}
//     alt={logoPnab.altText}
//     className="reference-logo"
//   />
//   {/* &nbsp;Parco Naturale Adamello Brenta */}
// </a>
// </div>
// <div>
// <a
//   href="https://www.campigliodolomiti.it/"
//   className="reference-link text-secondary"
//   target="_blank"
// >
//   <img
//     src={logoApt.src}
//     alt={logoApt.altText}
//     className="reference-logo"
//   />
//   {/* &nbsp;Apt Campiglio */}
// </a>
// </div>