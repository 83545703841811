import React from "react";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'


import "leaflet/dist/leaflet.css";

const lat = 46.16008632807889;
const lon = 10.761278396304554;
function Map() {

  const markerIcon = new L.Icon({
    iconUrl: require("assets/img/map-marker-icon.png").default,
    iconSize: [30, 30]
  });

  return (
    <>
      <div style={{ height: "40vh", width: "100%" }}>
        <MapContainer
          style={{ height: "100%", width: "100%" }}
          center={[lat, lon]}
          zoom={10}
          scrollWheelZoom={true}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[lat, lon]} icon={markerIcon}>
            <Popup class="text-center">
              Noi siamo qui! <br />
              Visualizza su Goggle Maps. <br />
              <a
                href={`https://maps.google.com/?ll=${lat},${lon}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                link
              </a>
            </Popup>
          </Marker>
        </MapContainer>
      </div>{" "}
    </>
  );
}

export default Map;
